import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/article",
  },
  {
    path: "/article",
    name: "Home",
    component: () => import("@/views/home"),
    children: [
      {
        path: "home_article", // 文章列表
        name: "home_article",
        component: () => import("@/components/article/ArticleList"),
        children: [
          {
            path: "home_article_page", // 文章详情页面
            name: "home_article_page",
            component: () => import("@/components/article/ArticlePage"),
          },
        ],
      },
    ],
  },
  {
    path: "/about",
    name: "About",
    component: () => import("@/views/about"),
    redirect: "/about/Data",
    children: [
      {
        path: "Data",
        name: "Data",
        component: () => import("@/views/about/Data"),
      },
      {
        path: "Skill",
        name: "Skill",
        component: () => import("@/views/about/Skill"),
      },
      {
        path: "Evaluate",
        name: "Evaluate",
        component: () => import("@/views/about/Evaluate"),
      },
      {
        path: "Like",
        name: "Like",
        component: () => import("@/views/about/Like"),
      },
      {
        path: "Works",
        name: "Works",
        component: () => import("@/views/about/Works"),
      },
    ],
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: () => import("@/views/feedback"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
